<template>
    <v-main>
        <v-select label="Tipo documental Empalme" rounded @change="get_info" return-object item-text="nombre" :items="tipos_documentales" ></v-select>
        <v-select label="Tipo documental Reporte" rounded @change="reporte_op" return-object item-text="nombre" :items="tipos_documentales" ></v-select>
        <v-btn @click="individual_send_errores()"> Repetir errores </v-btn>}
        <v-btn @click="delete_collections()">DROP</v-btn>
        <vue-excel-xlsx
            :data="desserts"
            :columns="columns"
            :filename="'FUID'"
            :sheetname="'FUID'" 

            
            >
            <v-btn class="mt-1" block rounded="" dark color="white" style="color:blue" >
                <span>
                    Descargar
                </span>
                <v-icon>
                    mdi-microsoft-excel
                </v-icon>
            </v-btn>
        </vue-excel-xlsx> 
        <h3>Hojas: {{page}}/{{parseInt(25973/250)+1}} </h3>
    </v-main>
</template>
<script>
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from 'vue'
Vue.use(VueExcelXlsx)
export default {
    data(){
        return {
            tipos_documentales: [],
            data: null,
            nombre_endpoint_almacenamiento: 'V1_Expedientecontribuyentepredial',
            errores: [],
            columns: [
                
                
                { label:"Fecha", field: "Fecha" },
                { label: "Numero_consecutivo", field: "Numero_consecutivo" },
                { label: "Referencia_del_concepto_del_acto_administrativo", field: "Referencia_del_concepto_del_acto_administrativo" },
                { label: 'Cantidad de imagenes Antiguas', field: 'Imagenes_Antiguas' },
                { label: 'Cantidad de imagenes Nuevas', field: 'Imagenes_Nuevas' },
                { label: 'Cantidad Total imagenes', field: 'Total' },
                //{ label: 'Beneficiario', field: 'Beneficiario' },
            ],
            desserts: [],
            page: 0,
        }
    },
    created(){
        this.consultar_tipos_documentales();
    },

    methods: {
        async delete_collections(){
            try {

                    const delete_collection = await this.$store.dispatch("llamado_delete",{
                        url: "https://docutest.educandote.co/types/collections/V1_Expedientecontribuyentepredial",
                        tipo_header:"indexador"
                    });  

                    console.log(delete_collection);
                    
                    /*
                    const delete_folder = await this.$store.dispatch("llamado_delete",{
                        url: "https://docutest.educandote.co/types/collections/CARPETAS_V1_Expedientecontribuyentepredial",
                        tipo_header:"indexador"
                    });  

                    console.log(delete_folder);

                    const delete_images = await this.$store.dispatch("llamado_delete",{
                        url: "https://docutest.educandote.co/types/collections/IMAGENES_V1_Expedientecontribuyentepredial",
                        tipo_header:"indexador"
                    });  
                    console.log(delete_images);
                    */
                    

                    

                    
            } catch (error) {
                console.log(error);
            }
        },
        async consultar_tipos_documentales(){
            

            const response = await this.$store.dispatch("llamado_get",{
                url: "https://audidoc.educandote.co/parse/classes/prueba01Sector/qfVGMzYB3l",
                tipo_header:"parse"
            });
            console.log(response);
            this.tipos_documentales = response.data.tipos_documentales


        },
       async get_info(document){
        //Datos con los que se va a loguear la persona
        console.log(document);
         let index,j,k;
            try {

                //let pages = parseInt(25973/250)+1 ;
                console.log(pages);
                85,87,93
                let pages = parseInt(708/250)+1
                for (this.page = 1 ; this.page <= pages ; this.page++ ) {

                    let response = await this.$store.dispatch('llamado_get',{
                    url: "https://audidoc.educandote.co/types/collections/"+document.nombre_endpoint_almacenamiento+"/documents/search?q="+'*'+"&query_by="+document.parametros[0].key+"&per_page=250&page="+this.page,
                    tipo_header: "indexador",
                    });
                    

                    //Paso #1 Generar la query para mandarlo al buscador
                    let body_carpeta = "";
                    let body_archivos = "";
                    let body_busqueda = "";

                    for (index = 0; index < response.data.hits.length; index++) {
                        
                        let busqueda = {};
                        
                        try {
                            busqueda = response.data.hits[index].document;
                        } catch (error) {
                            console.log(`Error JSON busqueda: ${index}`);
                        }
                        /*
                        let OP = {
                            Beneficiario: busqueda.Beneficiario,
                            Fecha: busqueda.Fecha,
                            Numero_de_orden: busqueda.Numero_de_orden
                        };

                        this.desserts.push(OP);
                       
                        
                        let fecha="",fecha_documento_int=0;
                        if(busqueda.Fecha.indexOf('-')>-1){
                            fecha = busqueda.Fecha.split('-');
                            fecha =  `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
                            //busqueda.Fecha = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
                            fecha_documento_int = Date.parse(new Date(fecha));
                            if(isNaN(fecha_documento_int)){
                                fecha_documento_int = 0;
                            }
                        }
                         */

                       

                        body_busqueda = body_busqueda + `{"id": "${busqueda.id}", "Referencia_catastral": "${busqueda.Referencia_catastral}" , "Cedula_o_nit": "${busqueda.Cedula_o_nit}", "Nombre_del_contribuyente": "${busqueda.Nombre_del_contribuyente}", "ST-Caja": "${busqueda['ST-Caja']}", "ST-Carpeta": "${busqueda['ST-Carpeta']}","ST-Entrepano": "${busqueda['ST-Entrepano']}","ST-Estante": "${busqueda['ST-Estante']}","ST-Modulo": "${busqueda['ST-Modulo']}","fecha_creacion_int": ${busqueda.fecha_creacion}, "order": ${busqueda.fecha_creacion},"estatus": "HABILITADA", "extended_properties": "{}"}\n`

                        //body_busqueda = body_busqueda + `{"id": "${busqueda.id}", "Nit_o_documento_de_identificacion": "${busqueda.Nit_o_documento_de_identificacion}" , "Fecha_documento": "${fecha}" , "fecha_documento_int": ${fecha_documento_int}, "Referencia_del_concepto_del_acto_administrativo": "${busqueda.Referencia_del_concepto_del_acto_administrativo}", "ST-Caja": "${busqueda['ST-Caja']}", "ST-Carpeta": "${busqueda['ST-Carpeta']}","ST-Entrepano": "${busqueda['ST-Entrepano']}","ST-Estante": "${busqueda['ST-Estante']}","ST-Modulo": "${busqueda['ST-Modulo']}","fecha_creacion_int": ${busqueda.fecha_creacion}, "order": ${busqueda.fecha_creacion},"estatus": "HABILITADA", "extended_properties": "{}"}\n`
                        //Paso #2 Generar la query para crear las carpetas
                        
                        for (j = 0; j < busqueda.carpetas.length; j++) {
                            
                            let carpeta = {};
                            try {
                                carpeta = JSON.parse(busqueda.carpetas[j]);
                            } catch (error) {
                                console.log(`Error JSON carpeta: Page: ${this.page} - Index: ${index} - Carpeta: ${j}`);
                            }
                        

                            //body = body + `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${info_file.autor}", "estado_imagen": "${info_file.estado_imagen}", "meta_datos": "", "nombre": "${info_file.nombre}", "nombre_sector": "${info_file.nombre_sector}", "sector": "${info_file.sector}", "tipo": "${info_file.tipo}", "token": "${info_file.token}", "fecha_creacion_int": ${Date.parse(new Date())}, "order": ${Date.parse(new Date())}, "extended_properties": "{}"}\n`

                            let estado_carpeta = '';
                            if(typeof carpeta.estado=='undefined'){
                                estado_carpeta = 'habilitada'
                            }else{
                                estado_carpeta = carpeta.estado;
                            }
                            
                            body_carpeta = body_carpeta + `{"nombre_carpeta": "${carpeta.nombre}", "id_carpeta": "${j}" , "pertenece_a": "${busqueda.id}", "estado_carpeta": "${estado_carpeta}", "fecha_creacion_int": ${busqueda.fecha_creacion+j}, "order": ${busqueda.fecha_creacion+j}, "extended_properties": "{}" }\n`
                        
                            for (k = 0; k < carpeta.archivos.length; k++) {
                                
                                try {
                                    let archivo = carpeta.archivos[k];

                                    let estado_imagen = "";

                                    if(typeof archivo.estado =='undefined'){
                                        estado_imagen = 'habilitada';
                                    }else{
                                        estado_imagen = archivo.estado;
                                    }

                                    body_archivos = body_archivos + `{"id_busqueda": "${busqueda.id}", "id_carpeta": "${j}", "autor": "${archivo.autor}", "estado_imagen": "${estado_imagen}", "meta_datos": "", "nombre": "${archivo.nombre}", "nombre_sector": "${archivo.nombre_sector}", "sector": "${archivo.sector}", "tipo": "${archivo.tipo}", "token": "${archivo.token}", "fecha_creacion_int": ${archivo.fecha_adjuntado}, "order": ${archivo.fecha_adjuntado}, "extended_properties": "{}"}\n`
                                } catch (error) {
                                     this.errores.push({
                                        page: this.page,
                                        busqueda: busqueda,
                                        carpeta: carpeta,
                                        imagenes: imagenes,
                                        error: error
                                    });
                                }
                            }
                        
                        
                        }
                    }

                    let busqueda, carpeta, imagenes
                    let url_busqueda = `https://docutest.educandote.co/types/collections/${this.nombre_endpoint_almacenamiento}/documents/import?action=create`
                    try {
                        busqueda = await this.$store.dispatch('llamado_post',{
                        url:url_busqueda,
                        body: body_busqueda,
                        tipo_header: "indexador"
                        });
                    } catch (error) {
                        this.errores.push({
                            url: url_busqueda,
                            body: body_busqueda,
                            page: this.page,
                            busqueda: busqueda,
                            carpeta: carpeta,
                            imagenes: imagenes,
                            error: error
                        });
                        console.log(error);
                    }

                    
                    let url_carpeta = `https://docutest.educandote.co/types/collections/CARPETAS_${this.nombre_endpoint_almacenamiento}/documents/import?action=create`
                    try {
                        carpeta = await this.$store.dispatch('llamado_post',{
                        url: url_carpeta,
                        body: body_carpeta,
                        tipo_header: "indexador"
                        });
                    } catch (error) {
                        this.errores.push({
                            url: url_carpeta,
                            body: body_carpeta,
                            page: this.page,
                            busqueda: busqueda,
                            carpeta: carpeta,
                            imagenes: imagenes,
                            error: error
                        });
                        console.log(error);
                    }
                    let url_imagen = `https://docutest.educandote.co/types/collections/IMAGENES_${this.nombre_endpoint_almacenamiento}/documents/import?action=create`
                    try {
                        //Paso #3 Genear la query para agregar las imagenes
                        imagenes = await this.$store.dispatch('llamado_post',{
                        url: url_imagen,
                        body: body_archivos,
                        tipo_header: "indexador"
                        });
                    } catch (error) {
                        this.errores.push({
                            url: url_imagen,
                            body: body_archivos,
                            page: this.page,
                            busqueda: busqueda,
                            carpeta: carpeta,
                            imagenes: imagenes,
                            error: error
                        });
                        console.log(error);
                    }
                    

                    
                    //let carpetas = JSON.parse(response.data.hits[0].document.carpetas[0]);

                    //console.log(carpetas);
                    //this.data = data.data;
    
                }
                
            } catch (error) {
                console.log(error);
            }

            console.log(this.errores);

            //Paginas con error 

         
        },
        async reporte_op(document){
            
            let pages = parseInt(35154/250)+1 ;
                console.log(pages);
                let index,j,k;
                
                for (this.page = 1; this.page <= pages ; this.page++) {

                    let response = await this.$store.dispatch('llamado_get',{
                    url: "https://audidoc.educandote.co/types/collections/"+document.nombre_endpoint_almacenamiento+"/documents/search?q="+'*'+"&query_by="+document.parametros[0].key+"&per_page=250&page="+this.page,
                    tipo_header: "indexador",
                    });
                    
                    console.log(response);
                    
                    for (index = 0; index < response.data.hits.length; index++) {
                        
                        let busqueda = {};
                        
                        try {
                            busqueda = response.data.hits[index].document;
                        } catch (error) {
                            console.log(`Error JSON busqueda: ${index}`);
                        }
                        let OP = {
                            Fecha: busqueda.Fecha,
                            Numero_consecutivo: busqueda.Numero_consecutivo,
                            Referencia_del_concepto_del_acto_administrativo: busqueda.Referencia_del_concepto_del_acto_administrativo,
                            //Numero_de_orden: busqueda.Numero_de_orden,
                            Imagenes_Antiguas: 0,
                            Imagenes_Nuevas: 0,
                            Total: 0,
                        };
                        this.desserts.push(OP);

                        
                        /*
                        let fecha="",fecha_documento_int=0;
                        if(busqueda.Fecha.indexOf('-')>-1){
                            fecha = busqueda.Fecha.split('-');
                            fecha =  `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
                            //busqueda.Fecha = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
                            fecha_documento_int = Date.parse(new Date(fecha));
                            if(isNaN(fecha_documento_int)){
                                fecha_documento_int = 0;
                            }
                        }
                        */

                        
                        //body_busqueda = body_busqueda + `{"id": "${busqueda.id}", "Beneficiario": "${busqueda.Beneficiario}" , "Fecha": "${fecha}" , "fecha_documento_int": ${fecha_documento_int}, "Numero_de_orden": "${busqueda.Numero_de_orden}", "ST-Caja": "${busqueda['ST-Caja']}", "ST-Carpeta": "${busqueda['ST-Carpeta']}","ST-Entrepano": "${busqueda['ST-Entrepano']}","ST-Estante": "${busqueda['ST-Estante']}","ST-Modulo": "${busqueda['ST-Modulo']}","fecha_creacion_int": ${busqueda.fecha_creacion}, "order": ${busqueda.fecha_creacion}, "extended_properties": "{}"}\n`

                        //Paso #2 Generar la query para crear las carpetas
                        
                        for (j = 0; j < busqueda.carpetas.length; j++) {
                            
                            let carpeta = {};
                            try {
                                carpeta = JSON.parse(busqueda.carpetas[j]);
                            } catch (error) {
                                console.log(`Error JSON carpeta: Page: ${this.page} - Index: ${index} - Carpeta: ${j}`);
                            }
                        

                            //body = body + `{"id_busqueda": "${busqueda.data.id.toString()}", "id_carpeta": "${carpeta.data.id.toString()}", "autor": "${info_file.autor}", "estado_imagen": "${info_file.estado_imagen}", "meta_datos": "", "nombre": "${info_file.nombre}", "nombre_sector": "${info_file.nombre_sector}", "sector": "${info_file.sector}", "tipo": "${info_file.tipo}", "token": "${info_file.token}", "fecha_creacion_int": ${Date.parse(new Date())}, "order": ${Date.parse(new Date())}, "extended_properties": "{}"}\n`

                            let estado_carpeta = '';
                            if(typeof carpeta.estado=='undefined'){
                                estado_carpeta = 'habilitada'
                            }else{
                                estado_carpeta = carpeta.estado;
                            }
                            
                            //body_carpeta = body_carpeta + `{"nombre_carpeta": "${carpeta.nombre}", "id_carpeta": "${j}" , "pertenece_a": "${busqueda.id}", "estado_carpeta": "${estado_carpeta}", "fecha_creacion_int": ${busqueda.fecha_creacion+j}, "order": ${busqueda.fecha_creacion+j}, "extended_properties": "{}" }\n`
                        
                            for (k = 0; k < carpeta.archivos.length && estado_carpeta=='habilitada'; k++) {
                                
                                try {
                                    let archivo = carpeta.archivos[k];

                                    let estado_imagen = "";

                                    if(typeof archivo.estado =='undefined'){
                                        estado_imagen = 'habilitada'

                                    }else{
                                        estado_imagen = archivo.estado
                                    }

                                    if(estado_imagen=='habilitada'){
                                        if(archivo.autor=='Plataforma antigua'){
                                            this.desserts[this.desserts.length-1].Imagenes_Antiguas++;
                                        }else{
                                            this.desserts[this.desserts.length-1].Imagenes_Nuevas++;
                                        }
                                        this.desserts[this.desserts.length-1].Total++;
                                    }
                                    

                                    //body_archivos = body_archivos + `{"id_busqueda": "${busqueda.id}", "id_carpeta": "${j}", "autor": "${archivo.autor}", "estado_imagen": "${estado_imagen}", "meta_datos": "", "nombre": "${archivo.nombre}", "nombre_sector": "${archivo.nombre_sector}", "sector": "${archivo.sector}", "tipo": "${archivo.tipo}", "token": "${archivo.token}", "fecha_creacion_int": ${archivo.fecha_adjuntado}, "order": ${archivo.fecha_adjuntado}, "extended_properties": "{}"}\n`
                                } catch (error) {
                                     this.errores.push({
                                        page: this.page,
                                        busqueda: busqueda,
                                        carpeta: carpeta,
                                        imagenes: "0",
                                        error: error
                                    });
                                }
                                
                                
                            }
                        
                        
                        }
                        
                    }
                    
                    
            }
        },

        async individual_send_errores(){

                console.log(this.errores);
                let errores = [];
                for (let index = 0; index < this.errores.length; index++) {

                    try {
                        //Paso #3 Genear la query para agregar las imagenes
                        let response = await this.$store.dispatch('llamado_post',{
                        url: this.errores[index].url,
                        body: this.errores[index].body,
                        tipo_header: "indexador"
                        });
                        console.log(response);
                    } catch (error) {
                        errores.push({
                            url: this.errores[index].url,
                            body: this.errores[index].body,
                            error: error
                        });
                        console.log(error);
                    }

                }

                this.errores = [...errores];

                console.log(this.errores);

        }
    },
}
</script>